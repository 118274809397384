<template functional>
  <div
    :style="{'backgroundColor':props.backgroundColor}"
  >
    <v-container
      :style="props.jumboStyle"
      fluid
      class="crop-triangle --bottom"
    >
      <v-layout
        row
        wrap
      >
        <v-container
          grid-list-xl
        >
          <v-flex xs12 xl8 offset-xl2>
            <v-layout
              row
              wrap
            >
              <v-flex
                xs12
                md6
                class="text-xs-center text-md-left"
              >
                <slot></slot>
              </v-flex>
              <v-flex
                xs12
                md6
                class="text-xs-center text-md-right"
              >
                <slot name="right"></slot>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-container>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  export default {
    props: {
      jumboStyle: {
        required: true,
        type: Object
      },
      backgroundColor: String,
    }
  }
</script>
