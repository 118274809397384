<template>
  <div style="position: relative">
    <jumbo-section :jumbo-style="jumboStyle">
      <template>
        <h1 class="display-2 jh-white--text mb-3">
          Vše pro rozvoj vaší kariéry<br /> na jednom místě
        </h1>
        <h6 class="headline jh-white--text font-weight-light mb-3">
          informace o pracovních místech a o mzdách | osobnostní testy |
          kariéroví poradci
        </h6>
        <v-btn
          color="jhM1"
          class="jh-white--text ml-0"
          to="/sebeevaluace"
        >
          Více informací
        </v-btn>
      </template>
      <template slot="right">
        <j-h-video :props="{
          allowFullScreen: true,
          preload: 'auto',
          sources: [
            {
              url: '/video/1080/jobhub.webm',
              type: 'video/webm'
            },
            {
              url: '/video/1080/jobhub.mp4',
              type: 'video/mp4'
            },
          ]
        }"
        />
      </template>
    </jumbo-section>
    <container-section set-card-height-match is-extra-padded is-main-slot-wider>
      <v-flex
        v-for="card in [
          {
            icon: 'flaticon-user',
            header: 'Sebeevaluační nástroj',
            text:
              'Nejdříve si můžete projít jeden z našich osobnostních testů, ve kterých vám dáme pohled na to, jací jste. Poté se můžete pustit do vyplnění komplexního JOBHUB profilu. Ten vám ukáže konkrétní povolání, na která se hodíte.',
            link: '/sebeevaluace',
            button: 'VSTOUPIT'
          },
          {
            icon: 'flaticon-instructions',
            header: 'Průvodce životními situacemi',
            text:
              'Jste čerstvý absolvent, matka po rodičovské, máte více než 50 let, nebo chcete jen změnit práci?<br/><br/> Nejste v tom sami. Připravili jsme pro vás návod, jak najít uplatnění v konkrétních životních situacích.',
            redirect: M3LINK+'zivotni-situace',
            button: 'Hledat situaci'
          },
          {
            icon: 'flaticon-briefcase',
            header: 'Katalog kariérových poradců a koučů',
            redirect: M3LINK+'poradci',
            text:
              'Najděte profesionála podle vašich představ, který vám pomůže v rozvoji pracovního života. Vyhledávání i registrace je zdarma.',
            button: 'Hledat poradce'
          },
          {
            icon: 'flaticon-approved',
            header: 'Informace o trhu<br />práce',
            text: 'Chcete si vytvořit životopis a nevíte jak začít? Zde vám pomůžeme s obsahem i formou vašeho životopisu, takže hledání nové práce bude zase o něco snazší.',
            link: '/informace-o-trhu-prace',
            button: 'Hledat informace'
          }
        ]"
        :key="card.header"
        xs12
        md6
        lg3
        class="py-0"
      >
        <v-card
          :raised="false"
          class="v-card--fade-bg"
          flat
        >
          <v-layout
            align-center
            justify-space-around
            column
            fill-height
          >
            <v-card-title class="text-xs-center card-title-icon">
              <v-icon x-large>
                {{ card.icon }}
              </v-icon>
            </v-card-title>
            <v-card-title
              primary-title
              class="text-xs-center card-title-real"
              style="max-width: 100%;"
            >
              <h4
                class="title mb-0 jh-m1--text"
                v-html="card.header"
              />
            </v-card-title>
            <v-card-text
              v-html="card.text"
            />
          </v-layout>
          <v-card-actions class="pb-4" style="text-align: center; display: block;">
            <template v-if="card.redirect">
              <v-btn
                :href="card.redirect"
                outline
                color="jhM4"
                class="jh-m4--text"
              >
                {{ card.button }}
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                :to="card.link"
                color="jhM4"
                outline
                class="jh-m4--text"
              >
                {{ card.button }}
              </v-btn>
            </template>
          </v-card-actions>
        </v-card>
      </v-flex>
    </container-section>
    <v-container
      :style="darkSection"
      fluid
      class="crop-triangle --both --reverse"
    >
      <v-container
        grid-list-xl
        class="px-0"
      >
        <v-flex
          xs12
          md10 offset-md1 lg8 offset-lg2 xl8 offset-xl2
          class="text-xs-center"
        >
          <h3 class="display-2 orange--text mb-3">
            Rychlý osobnostní test
          </h3>
          <h4 class="headline font-weight-light jh-white--text mb-3">
            Jednoduše si zvolte osobnostní typ, který vás nejlépe vystihuje. My vám zobrazíme pár povolání, která se pro tento typ osobnosti hodí.
          </h4>
        </v-flex>
        <v-flex
          xs12
          md10 offset-md1 lg8 offset-lg2 xl8 offset-xl2
          class="mb-5"
          style="position: relative"
        >
          <r-i-a-s-e-c
            :value="RIASECValue"
            class="hidden-sm-and-down"
            @input="loadUnitsByRiasec"
          />
          <r-i-a-s-e-c-responsive
            :value="RIASECValue"
            class="hidden-md-and-up"
            @input="loadUnitsByRiasec"
          ></r-i-a-s-e-c-responsive>
        </v-flex>
        <v-flex
          xs12
          md10 offset-md1 lg8 offset-lg2 xl8 offset-xl2
        >
          <profession
            v-for="(item, index) in RIASECUnits"
            :key="`${index}_riasec`"
            :title="item.title"
            :subtitle="item.direction"
            btn-detail-title="Detail NSP"
            color="jh-m1"
            icon="flaticon-job"
            class="jh-white--text"
            outline
            :url="`https://nsp.cz/jednotka-prace/${item.urlSlug}`"
            transparent
          >
            <v-btn
              slot="post-button"
              large
              color="jhM1"
              class="ml-1 mr-0"
              @click="downloadProfessionCard(item)"
            >
              <v-icon>flaticon-download</v-icon>&nbsp;&nbsp;Karta povolání
            </v-btn>
          </profession>
        </v-flex>
        <v-flex
          xs12
          md10 offset-md1 lg8 offset-lg2 xl6 offset-xl3
          class="text-xs-center"
        >
          <v-btn
            large
            outline
            color="jhM1"
            class="jh-m1--text mx-0"
            dark
            to="/sebeevaluace"
          >
            Zobrazit všechny testy
          </v-btn>
        </v-flex>
      </v-container>
    </v-container>
    <container-section is-extra-padded title="Příběhy lidí" title-class="jh-m2--text" is-main-slot-wider>
      <v-layout v-if="!isLifesitsLoading" fill-height row wrap>
        <v-flex v-for="item in loadLifesits" :key="item.nazev" xs12 md6 lg3>
          <vertical-card :title="item.nazev" title-class="headline mb-0 jh-m2--text text-xs-center py-5" btn-text="Zobrazit detail" btn-class="jh-m2--text"
                         :image-src="`/legacy-m3/uploads/images/methods/${item.image}`" :to="`${M3LINK}zivotni-situace/${item.id}/${item.nazev}`"
                         image-fallback="/card_tmp_img.jpeg"
          >
            <!--            <p slot="content">-->
            <!--              {{ item.perex }}-->
            <!--            </p>-->
          </vertical-card>
        </v-flex>
      </v-layout>
    </container-section>
    <v-container
      :style="darkSection"
      fluid
      grid-list-xl
      class="crop-triangle --both --reverse"
    >
      <v-container
        grid-list-xl
        class="px-0"
      >
        <v-flex
          xs12
          md8
          offset-md2
          class="text-xs-center"
        >
          <h3 class="display-2 jh-white--text mb-4">
            Katalog kariérových poradců & koučů
          </h3>
        </v-flex>
        <v-flex
          xs12
          sm8
          offset-sm2
          lg4
          offset-lg4
          class="text-xs-center mb-4"
        >
          <c-z-map
            :on-item-click="onMapClick"
            color-variation-class="m3"
          />
        </v-flex>
        <v-flex
          class="text-xs-center"
          xs12
          md8
          offset-md2
        >
          <v-btn
            :href="M3LINK+'poradci'"
            large
            color="jhM3"
            class="mx-0"
            dark
          >
            Zobrazit katalog poradců
          </v-btn>
        </v-flex>
      </v-container>
    </v-container>
    <container-section title="Nejnovější volná místa" is-main-slot-wider>
      <jobs ref="jobs"></jobs>

      <v-flex
        xs12
        class="text-xs-center"
      >
        <v-btn
          large
          color="jhM4"
          class="mx-0"
          dark
          @click="redirectToJobsPage"
        >
          Zobrazit všechny místa
        </v-btn>
        <v-btn
          large
          outline
          color="jhM4"
          class="jh-m4--text mx-0"
          dark
          to="/informace-o-trhu-prace"
        >
          Další informace z trhu práce
        </v-btn>
      </v-flex>
    </container-section>
  </div>
</template>

<script>
  import JHVideo from '~/components/JHVideo'
  import JumboSection from '~/components/layout/landing-page/JumboSection'
  import ContainerSection from '@/components/layout/landing-page/ContainerSection'
  import DownloadProfessionCardMixin from '~/components/DownloadProfessionCardMixin'
  import VerticalCard from '~/components/Card/VerticalCard'
  import CZMap from '~/components/CZMap'
  import StyleMixin from '~/components/styleMixin'
  import { LOGO, MUTATIONS } from '~/store'
  import RIASEC from '~/components/RIASEC/riasec'
  import RIASECResponsive from '~/components/RIASEC/riasec-responsive'
  import Profession from '@/components/Card/Profession'
  import Jobs from '~/components/Jobs'

  export default {
    layout: 'homepage',
    components: { CZMap, RIASEC, RIASECResponsive, Profession, Jobs, JumboSection, ContainerSection, VerticalCard, JHVideo },
    mixins: [StyleMixin, DownloadProfessionCardMixin],
    head() {
      return {
        title: 'JOBHUB | Vše pro rozvoj vaší kariéry na jednom místě ',
        description: ' informace o pracovních místech a o mzdách | osobnostní testy | kariéroví poradci ',
      }
    },
    data() {
      return {
        RIASECValue: 'R',
        RIASECUnits: [],
        isLifesitsLoading: false,
        Lifesits: [], // what a glorious name Lifesits, sounds like shit (thank Choleva :/)
        M3LINK: process.env.M3LINK
      }
    },
    fetch({ store }) {
      store.commit(MUTATIONS.SET_LOGO, LOGO.DEFAULT)
      store.commit(MUTATIONS.SET_FOOTER_COLOR)
    },
    mounted() {
      this.loadLifesits()
    },
    methods: {
      onMapClick(nuts) {
        location.href = `${process.env.M3LINK}poradci?region_id=${nuts.m3}`
      },
      redirectToJobsPage() {
        this.$router.push({ path: '/informace-o-trhu-prace/volna-mista', query: this.$refs.jobs.model })
      },
      async loadUnitsByRiasec(riasec) {
        this.RIASECValue = riasec
        const response = await this.$axios.$get(
          '/jh-api/lists/work-unit/by-riasec',
          { params: { riasec: riasec } }
        )
        this.RIASECUnits = response
      },
      async loadLifesits() { // what a glorious name Lifesits :D
        this.isLifesitsLoading = true
        try {
          const response = await this.$axios.$get('/legacy-m3/zivotni-situace.json', {})
          this.loadLifesits = response
        }catch (e) {
          // skip
        }finally {
          this.isLifesitsLoading = false
        }
      }
    }
  }
</script>
