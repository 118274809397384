<template>
  <v-window-item
    :value="value"
    class="text-xs-center"
    style="margin: 0 auto;"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.12 113.22">
      <defs>
        <linearGradient :id="`grad-i-${color}`" x1="544.98" x2="718.3" y1="325.19" y2="325.19" gradientTransform="matrix(1 0 0 -1 -312.25 665.27)"
                        gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#0aa2c0" />
          <stop offset="1" stop-color="#3c6bb4" />
        </linearGradient>
      </defs>
      <path :fill="`url(#grad-i-${color})`"
            d="M327.14 314.76L284 289.84a5.42 5.42 0 0 0-5.42 0l-43.12 24.91a5.42 5.42 0 0 0-2.71 4.69v49.83a5.42 5.42 0 0 0 2.71 4.7l25.63 14.79 2.57 1.48a5.42 5.42 0 0 0 5.41-9.39l-5.29-3.07-17.52-10.06a5.42 5.42 0 0 1-2.74-4.7v-37.31a5.42 5.42 0 0 1 2.71-4.69l32.35-18.67a5.42 5.42 0 0 1 5.42 0L316.3 321a5.42 5.42 0 0 1 2.7 4.7V363a5.42 5.42 0 0 1-2.71 4.69l-22.8 13.15a5.42 5.42 0 1 0 5.19 9.52l.22-.13L327.14 374a5.42 5.42 0 0 0 2.71-4.69v-49.86a5.42 5.42 0 0 0-2.71-4.69z"
            transform="translate(-232.73 -289.11)"
      />
      <text class="flaticon icon" :class="`ft-${color}`" transform="translate(37 62)" v-html="iconCode">
      </text>
      <path d="M48.06 93.14h1v17.88h-1z" class="lineWithDot" />
      <circle cx="48.56" cy="112.01" r="1.21" class="lineWithDot" />
    </svg>
    <h2 :class="`ft-${color}`">
      {{ title }}
    </h2>
    <p>{{ subtitle }}</p>
  </v-window-item>
</template>
<script>
  export default {
    props: {
      value: String,
      title: String,
      subtitle: String,
      icon: String,
      color: Number,
    },
    computed:{
      iconCode(){
        return `&#${this.icon};`
      }
    }
  }
</script>
<style scoped>
  .lineWithDot {
    fill: white;
  }

  .icon {
    font-size: 24px;
    fill: #fff;
  }

  .ft-5 {
    fill: #417fa9 !important;
    color: #417fa9 !important;
  }

  .ft-3 {
    fill: #11acbc !important;
    color: #11acbc !important;
  }

  .ft-4 {
    fill: #0a93a4 !important;
    color: #0a93a4 !important;
  }

  .ft-1 {
    fill: #76c043 !important;
    color: #76c043 !important;
  }

  .ft-2 {
    fill: #49ba7e !important;
    color: #49ba7e !important;
  }

  .ft-6 {
    fill: #5061ac !important;
    color: #5061ac !important;
  }

  p {
    color: white;
  }
</style>
