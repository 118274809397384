<template>
  <v-card
    flat
    color="transparent"
    tile
  >
    <v-item-group
      :value="value"
      class="text-xs-center"
      mandatory
    >
      <v-item
        v-for="(key,n) in riasec"
        :key="`btn-${n}`"
        :value="key"
      >
        <v-btn
          :input-value="key === value"
          class="mx-0 jhOrange riasec"
          icon
          color="jhOrange"
          @click="select(key)"
        >
          <v-icon :class="`ft-${n+1} ${key}`">
            flaticon-{{ icons[n] }}
          </v-icon>
        </v-btn>
      </v-item>
    </v-item-group>

    <v-window
      :value="value"
      class="text-xs-center"
      align-center
      align-content-center
      @change="select"
    >
      <item
        style="max-width: 200px"
        :color="1"
        :icon="iconsCode[0]"
        value="R"
        title="Intelektuální"
        subtitle="logičnost, racionalita, zvědavost, vynalézavost"
      ></item>
      <item
        style="max-width: 200px"
        :color="2"
        :icon="iconsCode[1]"
        value="I"
        title="Umělecký"
        subtitle="citlivost, svéhlavost, tvořivost, fantazie"
      ></item>
      <item
        style="max-width: 200px"
        :color="3"
        :icon="iconsCode[2]"
        value="A"
        title="Praktický"
        subtitle="řemeslná zdatnost, zručnost, vytrvalost"
      ></item>
      <item
        style="max-width: 200px"
        :color="4"
        :icon="iconsCode[3]"
        value="S"
        title="Společenský"
        subtitle="vřelost, pomoc druhým, přátelskost, soucit"
      ></item>
      <item
        style="max-width: 200px"
        :color="5"
        :icon="iconsCode[4]"
        value="E"
        title="Podnikavý"
        subtitle="úspěch, ctižádost, dominance, odpovědnost"
      ></item>
      <item
        style="max-width: 200px"
        :color="6"
        :icon="iconsCode[5]"
        value="C"
        title="Konvenční"
        subtitle="pečlivost, přesnost, vytrvalost, pořádnost"
      ></item>
    </v-window>
  </v-card>
</template>
<script>
import Item from '~/components/RIASEC/riasec-responsive-item'

const DEFAULT_SELECTED = 'R'
export default {
  components: { Item },
  props: {
    value: String
  },
  data: () => ({
    length: 6,
    riasec: ['R', 'I', 'A', 'S', 'E', 'C'],
    icons: ['microscope', 'light-bulb', 'wrench', 'support', 'style', 'work'],
    iconsCode: ['xf106', 'xf102', 'xf109', 'xf10b', 'xf101', 'xf10f']
  }),
  mounted() {
    this.select(DEFAULT_SELECTED)
  },
  methods: {
    onWindowInput(value) {
      console.log('onWindowInput', value)
    },
    select(riasec) {
      this.$emit('input', riasec)
    }
  }
}
</script>
<style scoped>
.ft-5 {
  color: #417fa9 !important;
}

.ft-3 {
  color: #11acbc !important;
}

.ft-4 {
  color: #0a93a4 !important;
}

.ft-1 {
  color: #76c043 !important;
}

.ft-2 {
  color: #49ba7e !important;
}

.ft-6 {
  color: #5061ac !important;
}

p {
  color: white;
}
</style>
