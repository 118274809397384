<!--SOURCE https://github.com/liamtarpey/View.js -->
<template>
  <div class="VJS_video-player-wrapper" tabindex="0" @keydown.space="togglePlayPause">
    <div v-if="showSpinner" class="VJS_video-spinner">
      <div class="VJS_video-spinner__inner"></div>
    </div>
    <video ref="videoPlayer" class="VJS_video-player" :preload="props.preload" @click="togglePlayPause">
      <source v-for="source in props.sources" :key="source.url" :src="source.url" :type="source.type" />
      Váš prohlížeč nepodporuje HTML5 video.
    </video>
    <div v-show="isOnShowScreen" class="VJS_show-screen" :style="previewImageStyle" @click="togglePlayPause">
      <i class="flaticon flaticon-menu-1"></i>
    </div>
    <div v-show="!isOnShowScreen" class="VJS_controls" :class="{ 'VJS_controls--fixed': !videoBeingPlayed }">
      <button class="VJS_controls__button"
              :class="{
                'VJS_controls__button--play': !videoBeingPlayed,
                'VJS_controls__button--pause': videoBeingPlayed
              }"
              @click="togglePlayPause"
      >
      </button>
      <div class="VJS_controls__bar">
        <div ref="progressBar" class="VJS_controls__progress" @click="skipToPosition($event)">
          <div v-if="timeElapsed" class="VJS_controls__progress-time">
            {{ timeElapsed }}
          </div>
          <span class="VJS_controls__progress-back" :class="{ 'VJS_started': percentagePlayed !== 0 }" :style="{ width: percentagePlayed }"></span>
          <div class="VJS_controls__progress-ranges">
            <span v-for="range in bufferRanges" :key="range.start" class="VJS_controls__progress-range" :style="{ left: range.start, width: range.end }"></span>
          </div>
        </div>
        <div class="VJS_controls__time">
          {{ timeRemaining }}
        </div>
        <div class="VJS_controls__volume">
          <div class="VJS_controls__volume-bar" :class="{ 'VJS_controls__volume-bar--active': volume >= 0.2 }" @click="adjustVolume(0.2)"></div>
          <div class="VJS_controls__volume-bar" :class="{ 'VJS_controls__volume-bar--active': volume >= 0.4 }" @click="adjustVolume(0.4)"></div>
          <div class="VJS_controls__volume-bar" :class="{ 'VJS_controls__volume-bar--active': volume >= 0.6 }" @click="adjustVolume(0.6)"></div>
          <div class="VJS_controls__volume-bar" :class="{ 'VJS_controls__volume-bar--active': volume >= 0.8 }" @click="adjustVolume(0.8)"></div>
          <div class="VJS_controls__volume-bar" :class="{ 'VJS_controls__volume-bar--active': volume === 1 }" @click="adjustVolume(1)"></div>
        </div>
        <div v-if="props.allowFullScreen" class="VJS_controls__full-screen" @click="enterFullScreen">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  // Private vars
  let vm = null
  let videoPlayer = null
  let progressBar = null
  const fileName = 'JHVideo'

  /**
   * Logs an error with the filename
   * @param {String} error
   */
  const logError = (error) => {
    console.error('View.JS:', fileName + ' -', error)
  }

  /**
   * Returns an integer percentage based on a mouse position click or hover
   * @param {Event}
   * @return {Number}
   */
  const getPercentageOffset = (e) => {
    return e.offsetX / progressBar.offsetWidth
  }

  /**
   * Returns a string percentage based on player duration and current time
   * @return {String} percentage played
   */
  const getPercentagePlayed = () => {
    const percentagePlayed = Math.floor((100 / videoPlayer.duration) * videoPlayer.currentTime)
    return percentagePlayed + '%'
  }

  /**
   * Gets current time of video based on a percentage value
   * @param {Number} percentage
   * @return {Number} time
   */
  const getCurrentTime = (percentage) => {
    return Math.floor(percentage * videoPlayer.duration)
  }

  /**
   * Gets remaining time in seconds based on player duration and current time
   * @return {Number} seconds
   */
  const getRemainingTime = () => {
    return Math.round(videoPlayer.duration - videoPlayer.currentTime)
  }

  /**
   * Converts seconds to a human readable format
   * Checks if hours exists and prepends if needed
   * @param {Number} num | seconds to convert
   * @return {String} | human readable string 00:00:00
   */
  const secondsToHumanReadable = (num) => {
    let hours = Math.floor(num / 3600)
    let minutes = Math.floor((num % 3600) / 60)
    let seconds = num % 60

    // Add leading zero if needed
    hours = (hours < 10) ? '0' + hours : hours
    minutes = (minutes < 10) ? '0' + minutes : minutes
    seconds = (seconds < 10) ? '0' + seconds : seconds

    // If hours > 0, return string with hours prepended
    if(hours > 0) {
      return hours + ':' + minutes + ':' + seconds
    }

    return minutes + ':' + seconds
  }

  /**
   * Shows time when hovering over the progress bar
   * If timeInSeconds is a number, we can update our exposed variable
   * @param {Event} mouse event
   */
  const showHoverTime = (e) => {
    const percentageOffset = getPercentageOffset(e)
    const timeInSeconds = getCurrentTime(percentageOffset)

    if(!isNaN(timeInSeconds)) {
      vm.timeElapsed = secondsToHumanReadable(timeInSeconds)
    }
  }

  /**
   * On progress event we get the player's buffered attribute.
   * From here we can calculate an array of ranges to show
   * at what point the video has been loaded.
   */
  const calculateBufferRanges = () => {

    // Reset ranges every time this is called
    vm.bufferRanges = []

    // Loop through all ranges to calculate start and end times
    for(let i=0, l=videoPlayer.buffered.length; i<l; i++) {
      const totalDuration = videoPlayer.duration
      const startTime = Math.floor((100 / totalDuration) * videoPlayer.buffered.start(i))
      const endTime = Math.floor((100 / totalDuration) * videoPlayer.buffered.end(i))
      vm.bufferRanges.push({
        start: startTime + '%',
        end: endTime + '%'
      })
    }
  }

  // Event listener for ended video
  const onVideoEnded = () => {
    vm.videoBeingPlayed = false
    vm.isOnShowScreen = true
  }

  // Click event handler for play/pause button
  const togglePlayPause = () => {

    if(vm.showSpinner) {
      return false
    }

    (vm.videoBeingPlayed) ? pauseVideo() : playVideo()
  }

  // Handles video play event
  const playVideo = () => {
    videoPlayer.play()
    vm.videoBeingPlayed = true
    vm.isOnShowScreen = false
  }

  // Handles video pause event
  const pauseVideo = () => {
    videoPlayer.pause()
    vm.videoBeingPlayed = false
  }

  // On timeupdate event, calculate remaining time of video
  const timeUpdate = () => {
    const timeRemainingInSeconds = getRemainingTime()
    vm.timeRemaining = secondsToHumanReadable(timeRemainingInSeconds)
    vm.percentagePlayed = getPercentagePlayed()
  }

  // Click event handler for skipping to a certain position in the video
  const skipToPosition = (e) => {
    const percentageOffset = getPercentageOffset(e)
    videoPlayer.currentTime = getCurrentTime(percentageOffset)
  }

  /**
   * Click event handler for volume control
   * @param {String} vol
   */
  const adjustVolume = (vol) => {
    vm.volume = vol
    videoPlayer.volume = vm.volume
  }

  // Make the player full screen (fallbacks for all modern browsers)
  const enterFullScreen = () => {
    if(videoPlayer.requestFullscreen) {
      videoPlayer.requestFullscreen()
    } else if(videoPlayer.webkitRequestFullScreen) {
      videoPlayer.webkitRequestFullScreen()
    } else if(videoPlayer.mozRequestFullScreen) {
      videoPlayer.mozRequestFullScreen()
    } else if (videoPlayer.msRequestFullscreen) {
      videoPlayer.msRequestFullscreen()
    }
  }

  // Shows buffering spinner
  const showSpinner = () => {
    vm.showSpinner = true
  }

  // Hides buffering spinner
  const hideSpinner = () => {
    vm.showSpinner = false
  }

  /**
   * Attaches all event listeners on init:
   *
   * - timeupdate | timeUpdate()            | ensures timer progresses during playback
   * - waiting    | showSpinner()           | shows the spinner when video is buffering
   * - canplay    | hideSpinner()           | hides the spinner when video stops buffering
   * - ended      | onVideoEnded()          | fires pause event on video to toggle button
   * - progress   | calculateBufferRanges() | loops through buffer ranges to show loaded chunks on progress bar
   * - mousemove  | showHoverTime()         | listens for mouse move to show a hover element with remaining time
   */
  const attachEventListeners = () => {
    videoPlayer.addEventListener('timeupdate', timeUpdate)
    videoPlayer.addEventListener('waiting', showSpinner)
    videoPlayer.addEventListener('canplay', hideSpinner)
    videoPlayer.addEventListener('ended', onVideoEnded)
    videoPlayer.addEventListener('progress', calculateBufferRanges)
    progressBar.addEventListener('mousemove', showHoverTime)
  }

  /**
   * Validate our main prop object that's passed through to our component.
   * @return {Object}
   */
  const getPropsValidation = () => {
    return {
      props: {
        type: Object,
        validator: function(prop) {

          const sourceValues = ['url', 'type']
          const preloadValues = ['none', 'auto', 'metadata']

          if(!prop.hasOwnProperty('allowFullScreen')) {
            logError('Missing `allowFullScreen` value in prop.')
            return false
          }

          if(!prop.hasOwnProperty('preload')) {
            logError('Missing `preload` value in prop.')
            return false
          } else if(preloadValues.indexOf(prop.preload) === -1) {
            logError('`preload` value does not exist - Accepted values are: `none`, `auto` or `metadata`.')
            return false
          }

          if(!prop.hasOwnProperty('sources') || !prop.sources.length || !Array.isArray(prop.sources)) {
            logError('`sources` value missing or badly constructed.')
            return false
          }

          for(let i=0, l=prop.sources.length; i<l; i++) {
            for(let j=0, k=sourceValues.length; j<k; j++) {
              if(!prop.sources[i].hasOwnProperty(sourceValues[j])) {
                logError('source index[' + i + '] is missing property `' + sourceValues[j] + '`')
                return false
              }
            }
          }

          return true
        }
      }
    }
  }

  /**
   * Construct object of public data
   * We expose this whole object publicly
   * All values are defaults
   */
  const getPublicData = () => {
    return {
      isOnShowScreen: true,
      showSpinner: false,
      bufferRanges: [],
      videoBeingPlayed: false,
      percentagePlayed: 0,
      volume: 0.6,
      timeRemaining: '00:00',
      timeElapsed: null
    }
  }

  /**
   * Return all exposed methods
   * @return {Object} | all public methods
   */
  const getPublicMethods = () => {
    return {
      togglePlayPause,
      skipToPosition,
      adjustVolume,
      enterFullScreen
    }
  }

  // Our default component setup
  export default {
    props: getPropsValidation(),
    data: getPublicData,
    computed: {
      previewImageStyle() {
        if(this.props.previewImage === undefined){
          return  {}
        }
        return {
          backgroundImage: `url(${this.props.previewImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        }
      },
    },
    mounted: function() {

      // Vars
      vm = this
      videoPlayer = vm.$refs.videoPlayer
      progressBar = vm.$refs.progressBar

      // Attach all event listeners on load
      attachEventListeners()
    },
    methods: getPublicMethods()
  }
</script>

<style scoped>
  .VJS_video-player-wrapper {
    position: relative;
    display: block;
  }
  .VJS_video-player-wrapper:hover .VJS_controls {
    opacity: 1;
    visibility: visible;
  }
  .VJS_video-player-wrapper:focus {
    outline: none;
  }
  .VJS_show-screen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .VJS_show-screen .flaticon{
    transform: perspective(1px) translateZ(0) rotateZ(-90deg);
    color: #F0F3F8;
    font-size: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -44px;
    margin-top: -67px;

    vertical-align: middle;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    transition-duration: 0.3s;
    transition-property: transform;
  }
  .VJS_show-screen .flaticon:hover{
    transform: scale(1.1) rotateZ(-90deg);
    cursor: pointer;
  }
  .VJS_video-player {
    display: block;
    width: 100%;
    font-family: arial, sans-serif;
  }
  .VJS_video-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -30px 0 0 -30px;
    border-radius: 5px;
    background: rgba(0,0,0,0.6);
    padding: 14px;
    z-index: 99;
  }
  .VJS_video-spinner__inner,
  .VJS_video-spinner__inner:after {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  .VJS_video-spinner__inner {
    border: 16px solid rgba(255, 255, 255,.4);
    border-left: 16px solid #ffffff;
    animation: viewJsSpinner 1.1s infinite linear;
  }
  .VJS_controls {
    position: absolute;
    left: 12px;
    bottom: 12px;
    right: 12px;
    padding: 4px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }
  .VJS_controls--fixed {
    opacity: 1;
    visibility: visible;
  }
  .VJS_controls__button {
    flex: 0 0 80px;
    display: block;
    position: relative;
    height: 40px;
    margin-right: 24px;
    text-align: center;
    background-color: rgba(0,0,0,.7);
    border-radius: 8px;
    border: none;
  }
  .VJS_controls__button:hover {
    cursor: pointer;
  }
  .VJS_controls__button:focus {
    outline: none;
  }
  .VJS_controls__button:before,
  .VJS_controls__button:after {
    content: '';
    position: absolute;
  }
  .VJS_controls__button--play:before {
    left: 50%;
    top: 50%;
    margin: -10px 0 0 -6px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 18px solid white;
  }
  .VJS_controls__button--pause:before,
  .VJS_controls__button--pause:after {
    height: 22px;
    width: 6px;
    background-color: white;
    top: 9px;
  }
  .VJS_controls__button--pause:before {
    left: 32px;
  }
  .VJS_controls__button--pause:after {
    left: 42px;
  }
  .VJS_controls__bar {
    display: flex;
    flex-grow: 1;
    align-items: center;
    background-color: rgba(0,0,0,.7);
    border-radius: 8px;
    height: 34px;
  }
  .VJS_controls__progress {
    flex: 1;
    margin: 0 20px;
    width: 100%;
    height: 6px;
    border-radius: 3px;
    background-color: black;
    position: relative;
  }
  .VJS_controls__progress:hover {
    cursor: pointer;
  }
  .VJS_controls__progress:hover .VJS_controls__progress-time {
    display: block;
  }
  .VJS_controls__progress-time {
    display: none;
    position: absolute;
    top: -40px;
    left: 50%;
    background-color: rgba(0,0,0,.7);
    color: #fff;
    padding: 4px 12px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .VJS_controls__progress-back {
    position: absolute;
    height: 6px;
    top: 0;
    left: 0;
    width: 0;
    background-color: #33ccff;
    border-radius: 3px;
    z-index: 2;
  }
  .VJS_controls__progress-ranges {
    position: absolute;
    height: 6px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    overflow: hidden;
  }
  .VJS_controls__progress-range {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    border-radius: 3px;
    background-color: grey;
  }
  .VJS_controls__time {
    color: white;
    font-size: 14px;
    margin-right: 12px;
  }
  .VJS_controls__volume {
    width: 32px;
    height: 18px;
    position: relative;
  }
  .VJS_controls__volume-bar {
    width: 3px;
    position: absolute;
    bottom: 0;
    background-color: white;
    transition: height .25s ease;
  }
  .VJS_controls__volume-bar--active {
    background-color: #33ccff;
  }
  .VJS_controls__volume-bar:hover {
    cursor: pointer;
  }
  .VJS_controls__volume-bar:first-child  { left:0; height:5px; }
  .VJS_controls__volume-bar:nth-child(2) { left:5px; height:8px; }
  .VJS_controls__volume-bar:nth-child(3) { left:10px; height:12px; }
  .VJS_controls__volume-bar:nth-child(4) { left:15px; height:15px; }
  .VJS_controls__volume-bar:last-child   { left:20px; height:18px; }
  .VJS_controls__volume-bar:first-child:hover  { height:8px; }
  .VJS_controls__volume-bar:nth-child(2):hover { height:11px; }
  .VJS_controls__volume-bar:nth-child(3):hover { height:15px; }
  .VJS_controls__volume-bar:nth-child(4):hover { height:18px; }
  .VJS_controls__volume-bar:last-child:hover   { height:21px; }
  .VJS_controls__full-screen {
    width: 26px;
    height: 16px;
    position: relative;
    transition: transform .2s ease;
    margin-right: 12px;
  }
  .VJS_controls__full-screen:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  .VJS_controls__full-screen span {
    position: absolute;
    width: 10px;
    height: 5px;
    display: block;
  }
  .VJS_controls__full-screen span:first-child {
    top: 0;
    left: 0;
    border-left: 2px solid white;
    border-top: 2px solid white;
  }
  .VJS_controls__full-screen span:nth-child(2) {
    top: 0;
    right: 0;
    border-top: 2px solid white;
    border-right: 2px solid white;
  }
  .VJS_controls__full-screen span:nth-child(3) {
    bottom: 0;
    left: 0;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
  }
  .VJS_controls__full-screen span:last-child {
    bottom: 0;
    right: 0;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
  }
  @keyframes viewJsSpinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
