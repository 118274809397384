function renderContainer(h, ctx) {

  let style = {}
  if (ctx.props.isExtraPadded === true) {
    style.paddingBottom = '90px'
  }

  let mainContainer = h('v-container', {
    style,
    attrs: {
      'grid-list-xl': true,
    }
  }, [
    h('v-layout', { attrs: { row: true, wrap: true } }, [
      renderTitle(h, ctx),
      renderPaddedTopSlot(h, ctx),
      renderTopSlot(h, ctx),
      renderMainSlot(h, ctx),
      renderPaddedBottomSlot(h, ctx),
      renderBottomSlot(h, ctx),
    ])
  ])

  if (ctx.props.isFluid) {
    // wrap with extra container to set background
    return h('v-container', {
      staticClass: 'pa-0',
      style: {
        'backgroundColor': ctx.props.backgroundColor
      },
      attrs: {
        fluid: true,
      }
    }, [mainContainer])
  }
  return mainContainer
}

function renderPaddedTopSlot(h, { slots }) {
  slots = slots()
  if (slots.paddedTop) {
    return h('v-flex', {
      attrs: {
        xs12: true,
        md10: true,
        'offset-md1': true,
        lg8: true,
        'offset-lg2': true,
        xl6: true,
        'offset-xl3': true,
      }
    }, [slots.paddedTop])
  }
}

function renderTopSlot(h, { slots }) {
  slots = slots()
  if (slots.top) {
    return slots.top
  }
}

function renderTitle(h, { props }) {
  if (props.title === null) {
    return null
  }
  return h('v-flex', { attrs: { xs12: true, }, staticClass: 'text-xs-center' }, [
    h('h3', { staticClass: `display-2 mb-3 ${props.titleClass}` }, [props.title])
  ])
}

function renderMainSlot(h, { slots, props }) {
  slots = slots()
  if (slots.default) {

    let directives = []
    if (props.setCardHeightMatch === true) {
      directives = [
        {
          name: 'match-heights',
          value: { el: ['.v-card__text', '.v-card__title'] },
        }
      ]
    }

    let attrs = {
      xs12: true,
      md10: true,
      'offset-md1': true,
      lg8: true,
      'offset-lg2': true,
    }
    if (props.isMainSlotWider === false) {
      attrs = Object.assign(attrs, {
        xl6: true,
        'offset-xl3': true,
      })
    }

    return h('v-flex', { attrs },
      [
        h('v-layout', {
            staticClass: 'mt-1 body-1',
            attrs: {
              row: true,
              wrap: true,
              'justify-center': props.isCentered
            },
            directives,
          },
          slots.default
        )
      ]
    )
  }

  return null
}

function renderPaddedBottomSlot(h, { slots }) {
  slots = slots()
  if (slots.paddedBottom) {
    return h('v-flex', {
      attrs: {
        xs12: true,
        md10: true,
        'offset-md1': true,
        lg8: true,
        'offset-lg2': true,
        xl6: true,
        'offset-xl3': true,
      }
    }, [slots.paddedBottom])
  }
}

function renderBottomSlot(h, { slots }) {
  slots = slots()
  if (slots.bottom) {
    return slots.bottom
  }
}

export default {
  functional: true,
  props: {
    title: String,
    titleClass: String,
    isCentered: {
      type: Boolean,
      default: true,
    },
    isFluid: {
      type: Boolean,
      default: false,
    },
    isMainSlotWider: {
      type: Boolean,
      default: false,
    },
    setCardHeightMatch: {
      type: Boolean,
      default: false,
    },
    isExtraPadded: {
      type: Boolean,
      default: false,
    },
    backgroundColor: String,
  },
  methods: {},
  render(h, ctx) {
    return renderContainer(h, ctx)
  }
}
