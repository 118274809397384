<template functional>
  <v-card
    height="100%"
    hover
  >
    <slot name="image">
      <v-img :src="props.imageSrc === '' ? props.imageFallback : props.imageSrc" :max-height="180" :min-height="180" class="crop-trapezoid --bottom">
      </v-img>
    </slot>
    <v-card-text>
      <h3 :class="props.titleClass">
        {{ props.title }}
      </h3>
      <slot name="content"></slot>
    </v-card-text>
    <v-spacer></v-spacer>
    <v-card-actions class="d-block text-xs-center">
      <v-btn v-jh-link="props.to !== undefined ? props.to : props.url" :class="props.btnClass" outline>
        {{ props.btnText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    props: {
      title: String,
      titleClass: String,
      btnText: String,
      btnClass: String,
      to: String,
      url: String,
      imageSrc: String,
      imageFallback: String,
    }
  }
</script>

<style scoped>
  .v-card{
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
  }
  .v-card__text{
    flex: 1 1 auto;
  }
</style>
