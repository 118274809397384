<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 612 190"
    :style="style"
    preserveAspectRatio="xMidYMid meet"
  >
    <defs>
      <linearGradient
        id="grad1"
        x1="253.89"
        y1="124.23"
        x2="358.48"
        y2="124.23"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#0da1bf" />
        <stop offset="1" stop-color="#0891a2" />
      </linearGradient>
      <linearGradient
        id="grad2"
        x1="2596.68"
        y1="1291.45"
        x2="2716.16"
        y2="1291.45"
        gradientTransform="translate(3202.24 1415.68) rotate(180)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#525ba8" />
        <stop offset="1" stop-color="#507ca9" />
      </linearGradient>
      <linearGradient
        id="grad3"
        x1="354.29"
        y1="124.23"
        x2="458.89"
        y2="124.23"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#0891a2" />
        <stop offset="1" stop-color="#507ca9" />
      </linearGradient>
      <linearGradient
        id="grad4"
        x1="166.91"
        y1="124.23"
        x2="254.74"
        y2="124.23"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#47bc95" />
        <stop offset="1" stop-color="#0da1bf" />
      </linearGradient>
      <linearGradient
        id="grad5"
        x1="2.76"
        y1="124.23"
        x2="204.33"
        y2="124.23"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#8ac63f" />
        <stop offset="1" stop-color="#47bc95" />
      </linearGradient>
    </defs>
    <g
      :class="{ selected: value === 'R' }"
      class="item"
      @click="select('R')"
    >
      <rect
        fill="#fff"
        fill-opacity="0"
        fill-rule="nonzero"
        x="0"
        y="0"
        width="105"
        height="100%"
      />
      <line class="line" x1="54.99" y1="51.83" x2="54.99" y2="71.02" />
      <circle class="dot" cx="54.99" cy="51.05" r="3.21" />
      <polygon
        class="hexa"
        points="80.34 76.58 30.66 76.58 5.82 119.6 30.66 162.62 80.34 162.62 105.17 119.6 80.34 76.58"
      />
      <text class="flaticon icon" transform="translate(42.91 124.04)">
        &#xf106;
      </text>
      <text class="ft-5" transform="translate(21.83 10.74)">
        Intelektuální
      </text>
      <text class="para" transform="translate(12.66 38.21)">
        logičnost, racionalita
      </text>
      <text class="para" transform="translate(4.79 26.49)">
        zvědavost, vynalézavost
      </text>
    </g>
    <g
      :class="{ selected: value === 'I' }"
      class="item"
      @click="select('I')"
    >
      <rect
        fill="#fff"
        fill-opacity="0"
        fill-rule="nonzero"
        x="105"
        y="0"
        width="100"
        height="100%"
      />
      <line class="line" x1="155.41" y1="51.83" x2="155.41" y2="71.02" />
      <circle class="dot" cx="155.41" cy="51.05" r="3.21" />
      <polygon
        class="hexa"
        points="179.71 76.58 130.04 76.58 105.2 119.6 130.04 162.62 179.71 162.62 204.55 119.6 179.71 76.58"
      />
      <text class="flaticon icon" transform="translate(143.41 124.04)">
        &#xf102;
      </text>
      <text class="ft-4" transform="translate(129.06 10.74)">
        Umělecký
      </text>
      <text class="para" transform="translate(113.44 38.21)">
        citlivost, svéhlavost
      </text>
      <text class="para" transform="translate(116.53 26.49)">
        tvořivost, fantazie
      </text>
    </g>
    <g
      :class="{ selected: value === 'A' }"
      class="item"
      @click="select('A')"
    >
      <rect
        fill="#fff"
        fill-opacity="0"
        fill-rule="nonzero"
        x="205"
        y="0"
        width="100"
        height="100%"
      />
      <line class="line" x1="255.16" y1="51.83" x2="255.16" y2="71.02" />
      <circle class="dot" cx="255.16" cy="51.05" r="3.21" />
      <polygon
        class="hexa"
        points="279.27 76.58 229.6 76.58 204.76 119.6 229.6 162.62 279.27 162.62 304.11 119.6 279.27 76.58"
      />
      <text class="flaticon icon" transform="translate(243.07 124.04)">
        &#xf109;
      </text>
      <text class="ft-3" transform="translate(230.26 11.09)">
        Praktický
      </text>
      <text class="para" transform="translate(215.29 38.57)">
        řemeslná zdatnost
      </text>
      <text class="para" transform="translate(213.22 26.85)">
        zručnost, vytrvalost
      </text>
    </g>
    <g
      :class="{ selected: value === 'S' }"
      class="item"
      @click="select('S')"
    >
      <rect
        fill="#fff"
        fill-opacity="0"
        fill-rule="nonzero"
        x="305"
        y="0"
        width="100"
        height="100%"
      />
      <line class="line" x1="355.57" y1="51.83" x2="355.57" y2="71.02" />
      <circle class="dot" cx="355.57" cy="51.05" r="3.21" />
      <polygon
        class="hexa"
        points="379.77 76.58 330.09 76.58 305.25 119.6 330.09 162.62 379.77 162.62 404.61 119.6 379.77 76.58"
      />
      <text class="flaticon icon" transform="translate(343.41 124.04)">
        &#xf10b;
      </text>
      <text class="ft-6" transform="translate(322.6 10.74)">
        Společenský
      </text>
      <text class="para" transform="translate(307.63 38.21)">
        vřelost, pomoc druhým
      </text>
      <text class="para" transform="translate(315.88 26.49)">
        přátelskost, soucit
      </text>
    </g>
    <g
      :class="{ selected: value === 'E' }"
      class="item"
      @click="select('E')"
    >
      <rect
        fill="#fff"
        fill-opacity="0"
        fill-rule="nonzero"
        x="405"
        y="0"
        width="100"
        height="100%"
      />
      <line class="line" x1="455.33" y1="51.83" x2="455.33" y2="71.02" />
      <circle class="dot" cx="455.33" cy="51.05" r="3.21" />
      <polygon
        class="hexa"
        points="480.17 76.58 430.5 76.58 405.66 119.6 430.5 162.62 480.17 162.62 505.01 119.6 480.17 76.58"
      />
      <text
        class="flaticon icon"
        style="content: '\f100';"
        transform="translate(443.41 124.04)"
      >
        &#xf101;
      </text>
      <text class="ft-1" transform="translate(427.63 10.74)">
        Podnikavý
      </text>
      <text class="para" transform="translate(418.46 38.21)">
        úspěch, ctižádost
      </text>
      <text class="para" transform="translate(402.21 26.49)">
        dominance, odpovědnost
      </text>
    </g>
    <g
      :class="{ selected: value === 'C' }"
      class="item"
      @click="select('C')"
    >
      <rect
        fill="#fff"
        fill-opacity="0"
        fill-rule="nonzero"
        x="505"
        y="0"
        width="105"
        height="100%"
      />
      <line class="line" x1="555.75" y1="51.83" x2="555.75" y2="71.02" />
      <circle class="dot" cx="555.75" cy="51.05" r="3.21" />
      <polygon
        class="hexa"
        points="580.59 76.58 530.91 76.58 506.07 119.6 530.91 162.62 580.59 162.62 605.42 119.6 580.59 76.58"
      />
      <text class="flaticon icon" transform="translate(543.91 124.04)">
        &#xf10f;
      </text>
      <text class="ft-2" transform="translate(527.94 10.74)">
        Konvenční
      </text>
      <text class="para" transform="translate(515.76 26.49)">
        pečlivost, přesnost
      </text>
      <text class="para" transform="translate(510.47 38.57)">
        vytrvalost, pořádnost
      </text>
    </g>
    <g>
      <path
        class="grad-1"
        d="M287.85,170.1a5.43,5.43,0,0,1-4.69,2.71H233.32a5.44,5.44,0,0,1-4.69-2.71l-16.3-28.24a5.42,5.42,0,0,1,0-5.42h0a5.42,5.42,0,0,1,9.39,0l13.18,22.82a5.43,5.43,0,0,0,4.69,2.71H276.9a5.43,5.43,0,0,0,4.69-2.71c.12-.24,46.54-80.66,46.68-80.9A5.42,5.42,0,0,1,333,75.64h49.83a5.41,5.41,0,0,1,4.69,2.71l16.44,28.47a5.42,5.42,0,0,1-2.07,7.46h0a5.42,5.42,0,0,1-7.32-2l-13.3-23a5.41,5.41,0,0,0-4.69-2.71H339.23a5.41,5.41,0,0,0-4.69,2.71S307.43,136.19,287.85,170.1Z"
        transform="translate(-2.76 -4.63)"
      />
      <path
        class="grad-2"
        d="M482.44,159.25a5.42,5.42,0,0,1-4.69,2.71H440.43a5.42,5.42,0,0,1-4.69-2.71l-13.18-22.84a5.42,5.42,0,0,0-9.4,0h0a5.42,5.42,0,0,0,0,5.41l16.31,28.26a5.42,5.42,0,0,0,4.69,2.71H484a5.42,5.42,0,0,0,4.69-2.71c30.69-53.15,22.61-39.2,46.7-80.9a5.42,5.42,0,0,1,4.69-2.71H577.4a5.42,5.42,0,0,1,4.69,2.71l18.66,32.32a5.42,5.42,0,0,1,0,5.42L582.1,159.26A5.42,5.42,0,0,1,577.4,162H540.09a5.42,5.42,0,0,1-4.69-2.71l-13.14-22.77a5.42,5.42,0,0,0-9.4,0h0a5.42,5.42,0,0,0,0,5.41l16.27,28.19a5.42,5.42,0,0,0,4.69,2.71h49.84a5.42,5.42,0,0,0,4.69-2.71l24.92-43.16a5.42,5.42,0,0,0,0-5.42L588.36,78.36a5.42,5.42,0,0,0-4.69-2.71H533.83a5.42,5.42,0,0,0-4.69,2.71Z"
        transform="translate(-2.76 -4.63)"
      />
      <path
        class="grad-3"
        d="M388.26,170.1a5.43,5.43,0,0,1-4.69,2.71H333.72A5.44,5.44,0,0,1,329,170.1l-16.3-28.24a5.42,5.42,0,0,1,0-5.42h0a5.42,5.42,0,0,1,9.39,0l13.18,22.82A5.43,5.43,0,0,0,340,162H377.3a5.43,5.43,0,0,0,4.69-2.71c.12-.24,46.54-80.66,46.68-80.9a5.42,5.42,0,0,1,4.69-2.71H483.2a5.41,5.41,0,0,1,4.69,2.71l16.44,28.47a5.42,5.42,0,0,1-2.07,7.46h0a5.42,5.42,0,0,1-7.32-2l-13.3-23a5.41,5.41,0,0,0-4.69-2.71H439.63a5.41,5.41,0,0,0-4.69,2.71S407.84,136.19,388.26,170.1Z"
        transform="translate(-2.76 -4.63)"
      />
      <path
        class="grad-4"
        d="M187.29,170.1a5.42,5.42,0,0,1-4.69,2.71H132.75a5.44,5.44,0,0,1-4.69-2.71l-16.3-28.24a5.42,5.42,0,0,1,0-5.42h0a5.42,5.42,0,0,1,9.39,0l13.18,22.82A5.43,5.43,0,0,0,139,162h37.32a5.43,5.43,0,0,0,4.69-2.71c19-32.9,26.25-45.5,46.68-80.9a5.42,5.42,0,0,1,4.69-2.71h49.83a5.41,5.41,0,0,1,4.69,2.71l16.44,28.47a5.42,5.42,0,0,1-2.07,7.46h0a5.42,5.42,0,0,1-7.32-2l-13.3-23A5.41,5.41,0,0,0,276,86.49H238.66A5.41,5.41,0,0,0,234,89.2S205.79,138.05,187.29,170.1Z"
        transform="translate(-2.76 -4.63)"
      />
      <path
        class="grad-5"
        d="M134.32,89.2A5.42,5.42,0,0,1,139,86.49h37.31A5.42,5.42,0,0,1,181,89.2L194.21,112a5.42,5.42,0,0,0,9.39,0h0a5.42,5.42,0,0,0,0-5.41L187.29,78.36a5.42,5.42,0,0,0-4.69-2.71H132.76a5.42,5.42,0,0,0-4.69,2.71s-35.64,61.74-46.7,80.9A5.42,5.42,0,0,1,76.67,162H39.36a5.42,5.42,0,0,1-4.69-2.71L16,126.94a5.42,5.42,0,0,1,0-5.42L34.66,89.2a5.42,5.42,0,0,1,4.69-2.71H76.67a5.42,5.42,0,0,1,4.69,2.71L94.51,112a5.42,5.42,0,0,0,9.39,0h0a5.42,5.42,0,0,0,0-5.41L87.63,78.36a5.42,5.42,0,0,0-4.69-2.71H33.1a5.42,5.42,0,0,0-4.69,2.71L3.49,121.52a5.42,5.42,0,0,0,0,5.42L28.4,170.1a5.42,5.42,0,0,0,4.69,2.71H82.93a5.42,5.42,0,0,0,4.69-2.71Z"
        transform="translate(-2.76 -4.63)"
      />
    </g>
  </svg>
</template>
<script>
  import IE11Mixin from '~/components/IE11Mixin'

  export default {
    mixins: [IE11Mixin],
    props: {
      value: String,
    },
    computed: {
      style() {
        if (this.isIE) {
            // IE11 HACK
            return {
              transform: 'scale(1.4)',
              paddingTop: '50px'
            }
        }

        return {}
      }
    },
    methods: {
      select(riasec) {
        this.$emit('input', riasec)
      }
    }
  }
</script>
<style scoped>
  .item {
    cursor: pointer;
    opacity: 0;
    transition: 0.5s all;
  }

  .item.selected,
  .item:hover {
    opacity: 1;
  }

  .dot,
  .hexa,
  .para,
  .line {
    fill: #fff;
  }

  .grad-1 {
    fill: url(#grad1);
  }

  .grad-2 {
    fill: url(#grad2);
  }

  .grad-3 {
    fill: url(#grad3);
  }

  .grad-4 {
    fill: url(#grad4);
  }

  .grad-5 {
    fill: url(#grad5);
  }

  .line {
    stroke: #fff;
    stroke-miterlimit: 10;
    stroke-width: 1.29px;
  }

  .ft-6,
  .ft-3,
  .ft-5,
  .ft-4,
  .ft-2,
  .ft-1 {
    font-size: 12.89px;
  }

  .ft-1 {
    fill: #417fa9;
  }

  .para {
    font-size: 10.31px;
  }

  .ft-6 {
    fill: #0a93a4;
  }

  .ft-3 {
    fill: #11acbc;
  }

  .ft-5 {
    fill: #76c043;
  }

  .ft-4 {
    fill: #49ba7e;
  }

  .ft-2 {
    fill: #5061ac;
  }

  .icon {
    font-size: 24px;
    fill: #4f7cad;
  }
</style>
